
export default {
  tooltip: {
    trigger: 'axis',
    transitionDuration: 0,
    extraCssText: 'width: 200px',
    appendToBody: true
  },
  legend: {
    data: []
  },
  grid: {
    top: '3%',
    left: '2%',
    right: '4%',
    bottom: '4%',
    containLabel: true
  },
  xAxis: {
    type: 'category',
    boundaryGap: false,
    data: [],
    axisLine: {
      lineStyle: {
        color: '#000',
        width: 0.5
      }
    }
  },
  yAxis: [
    // 0
    {
      type: 'value',
      position: 'left',
      minInterval: 1,
      axisLine: {
        show: true,
        lineStyle: {
          color: '#666',
          width: 0.5
        }
      },
      axisTick: {
        show: true
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: '#e5e5e5'
        }
      },
      axisLabel: {
        interval: 'auto',
        showMaxLabel: true,
        showMinLabel: true,
        formatter: '{value}'
      }
    }
  ],
  series: [
    {
      name: '',
      type: 'line',
      data: [],
      smooth: true,
      yAxisIndex: 0,
      symbol: 'circle', // 设定为实心点
      symbolSize: 6, // 设定实心点的大小
      itemStyle: {
        color: '#8A59FB',
        lineStyle: {
          color: '#8A59FB',
          width: 1
        }
    },
      areaStyle: {
        color: 'white'
      }
    }
  ]
}
